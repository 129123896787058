:root {
  --calendar-width: 200px;
  --calendar-height: 100px;
}

/* Combine styles for splash_wrapper */
.splash_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    45deg,
    #305270, #284b60, #20444f, #173d3e, #0e362e, #052f1d
  );
  background-size: 600% 600%;
  animation: Gradient 16s ease infinite;
}

.logo_container {
  margin-bottom: 2rem;
}

.loading_text {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #284b60; /* Using the Adjusted Indigo dye from your pentenary color theme */
  letter-spacing: 1.5px;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* splash calendar animation */

.github_calendar_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px; /* More space from the animation */
  padding: 20px;
  /* width: 200px;
  height: 100px; */
  width: var(--calendar-width);
  height: var(--calendar-height);
}

/* 
  .rotating-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Place it below the GitHub calendar 
}
*/

.rotating-border rect {
  width: var(--calendar-width);
  height: var(--calendar-height);
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: rotateBorder 9s infinite linear, dashOffset 9s infinite linear;
}

.calendar-box {
  position: relative;
  z-index: 1; /* Place it above the rotating border */
}

@keyframes rotateBorder {
  0% {
    /* stroke: #4db0a8; */
    stroke: red;
  }
  50% {
    stroke: #052f1d;
  }
  100% {
    /* stroke: #4db0a8; */
    stroke: red;
  }
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
