@import '~react-pdf/dist/esm/Page/AnnotationLayer.css';
@import '~react-pdf/dist/esm/Page/TextLayer.css';
// <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap" rel="stylesheet">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

html {
  z-index: 1;
  height: 100%;
  width: 100%;
  margin-left: -1rem;
}

main {
  // height: 400vh;
  display: flex !important;
}

.bg {
  background: url('../images/bg4.jpg') no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -3;
}
.bg:before {
  content: '';
  width: 100%;
  height: 100%;
  background: #000;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.3;
}
@keyframes sf-fly-by-1 {
  from {
    transform: translateZ(-600px);
    opacity: 0.5;
  }
  to {
    transform: translateZ(0);
    opacity: 0.5;
  }
}
@keyframes sf-fly-by-2 {
  from {
    transform: translateZ(-1200px);
    opacity: 0.5;
  }
  to {
    transform: translateZ(-600px);
    opacity: 0.5;
  }
}
@keyframes sf-fly-by-3 {
  from {
    transform: translateZ(-1800px);
    opacity: 0.5;
  }
  to {
    transform: translateZ(-1200px);
    opacity: 0.5;
  }
}
.star-field {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-perspective: 600px;
  perspective: 600px;

  z-index: -1;
}
.star-field .layer {
  box-shadow: -411px -476px #cccccc, 777px -407px #d4d4d4, -387px -477px #fcfcfc,
    -91px -235px #d4d4d4, 491px -460px #f7f7f7, 892px -128px #f7f7f7,
    758px -277px #ededed, 596px 378px #cccccc, 647px 423px whitesmoke,
    183px 389px #c7c7c7, 524px -237px #f0f0f0, 679px -535px #e3e3e3,
    158px 399px #ededed, 157px 249px #ededed, 81px -450px #ebebeb,
    719px -360px #c2c2c2, -499px 473px #e8e8e8, -158px -349px #d4d4d4,
    870px -134px #cfcfcf, 446px 404px #c2c2c2, 440px 490px #d4d4d4,
    414px 507px #e6e6e6, -12px 246px #fcfcfc, -384px 369px #e3e3e3,
    641px -413px #fcfcfc, 822px 516px #dbdbdb, 449px 132px #c2c2c2,
    727px 146px #f7f7f7, -315px -488px #e6e6e6, 952px -70px #e3e3e3,
    -869px -29px #dbdbdb, 502px 80px #dedede, 764px 342px #e0e0e0,
    -150px -380px #dbdbdb, 654px -426px #e3e3e3, -325px -263px #c2c2c2,
    755px -447px #c7c7c7, 729px -177px #c2c2c2, -682px -391px #e6e6e6,
    554px -176px #ededed, -85px -428px #d9d9d9, 714px 55px #e8e8e8,
    359px -285px #cfcfcf, -362px -508px #dedede, 468px -265px #fcfcfc,
    74px -500px #c7c7c7, -514px 383px #dbdbdb, 730px -92px #cfcfcf,
    -112px 287px #c9c9c9, -853px 79px #d6d6d6, 828px 475px #d6d6d6,
    -681px 13px #fafafa, -176px 209px #f0f0f0, 758px 457px #fafafa,
    -383px -454px #ededed, 813px 179px #d1d1d1, 608px 98px whitesmoke,
    -860px -65px #c4c4c4, -572px 272px #f7f7f7, 459px 533px #fcfcfc,
    624px -481px #e6e6e6, 790px 477px #dedede, 731px -403px #ededed,
    70px -534px #cccccc, -23px 510px #cfcfcf, -652px -237px whitesmoke,
    -690px 367px #d1d1d1, 810px 536px #d1d1d1, 774px 293px #c9c9c9,
    -362px 97px #c2c2c2, 563px 47px #dedede, 313px 475px #e0e0e0,
    839px -491px #e3e3e3, -217px 377px #d4d4d4, -581px 239px #c2c2c2,
    -857px 72px #cccccc, -23px 340px #dedede, -837px 246px white,
    170px -502px #cfcfcf, 822px -443px #e0e0e0, 795px 497px #e0e0e0,
    -814px -337px #cfcfcf, 206px -339px #f2f2f2, -779px 108px #e6e6e6,
    808px 2px #d4d4d4, 665px 41px #d4d4d4, -564px 64px #cccccc,
    -380px 74px #cfcfcf, -369px -60px #f7f7f7, 47px -495px #e3e3e3,
    -383px 368px #f7f7f7, 419px 288px #d1d1d1, -598px -50px #c2c2c2,
    -833px 187px #c4c4c4, 378px 325px whitesmoke, -703px 375px #d6d6d6,
    392px 520px #d9d9d9, -492px -60px #c4c4c4, 759px 288px #ebebeb,
    98px -412px #c4c4c4, -911px -277px #c9c9c9;
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  width: 4px;
  border-radius: 2px;
}
.star-field .layer:nth-child(1) {
  animation: sf-fly-by-1 5s linear infinite;
}
.star-field .layer:nth-child(2) {
  animation: sf-fly-by-2 5s linear infinite;
}
.star-field .layer:nth-child(3) {
  animation: sf-fly-by-3 5s linear infinite;
}
.star-field .layer:nth-child(4) {
  animation: sf-fly-by-4 5s linear infinite;
}
.star-field .layer:nth-child(5) {
  animation: sf-fly-by-5 5s linear infinite;
}

.card-space {
  margin-bottom: 1rem;
}
.about-paragraph {
  margin-bottom: 1rem;
}

.quote-text {
  margin-bottom: 1rem;
}

.MASTER-DIV {
  display: flex;
  z-index: 5;
  width: 0;
  padding: 0;
  margin: 0;
  background: gray.200;
}

canvas {
  height: 100%;
}

a {
  text-decoration: none;
  font-style: none;
}

#ignoreStyles {
  all: initial;
  * {
    all: unset;
  }
}

footer {
  height: 0;
}

.home-section {
  position: relative;
  z-index: -2;
  background: inherit;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}
/*---------------------------------- Iframe bars ----------------------------------------*/
div.frame-container.container {
  float: left;
  z-index: -1;
  height: 100%;
  position: fixed;
}

.container.iframe-div {
  padding: 0;
}

.iframe-div {
  padding: 0;
  margin: 0;
}

.framer-72rtr7 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.framer-body-augiA20Il {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .framer-1uhmoka-container {
// 	height: 100vh;
// 	margin-top: 50%;
// }
.framer-1uhmoka-container .div {
  // background-repeat: repeat-y;
  overflow: auto;
  margin-top: 50%;
}

.frame {
  z-index: 1;
  min-width: 100%;
  max-height: 100%;
  padding: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
  position: relative;
  right: 0;
  top: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #319795;
}

.cyan {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url('./pre.svg');
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: 'rgba(173, 216, 230, 0.7)';
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: 'rgba(173, 216, 230, 0.7)';
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: 'rgba(173, 216, 230, 0.7)';
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */

#home {
  justify-content: center;
  margin: auto;
  width: 100%;
  font: 12px/18px 'opensans-bold', sans-serif;
  color: white;
  text-transform: uppercase;
  z-index: 5;
  letter-spacing: 5px;
}

.sticky {
  background-color: #1b1a2ea9;
  transition: all 0.3s ease-out;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
}

.navbar {
  top: 0;
  left: 0;
  right: 0;
  // position: fixed;
  // position: sticky;
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 52;
  max-height: 100px;
  background-color: transparent;
  transition: all 0.3s ease-out;
  padding: 0.3rem 2rem;
  font-size: 1.2rem;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-bottom: 3px;
}

.logo-nav,
.logoGroup {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-button {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}

.navbar-toggler {
  position: relative;
  background-color: transparent;
  border-color: transparent;
}

.navbar-toggler span {
  display: block;
  background-color: #319795;
  height: 4px;
  width: 27px;
  margin: 5px 0;
  transform: rotate(0deg);
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    background-color: #181a27;
    justify-content: center;
  }

  .nav-link {
    padding: 0.7rem 1rem;
  }
}

.navbar-nav {
  align-items: center;
}

.navbar-brand,
.logo {
  display: flex;
  height: 3em;
  width: 6em;
  color: rgb(250, 250, 250);
}

.navbar-nav .nav-link {
  flex-grow: 1;
  color: white;
  padding: 0 1rem;
  text-align: center;
}

.nav-item {
  padding-left: 10px;
  margin: auto;
  align-items: center;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: '';
  position: relative;
  width: 0;
  border-radius: 16px;
  background: #319795;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.navbar-nav .nav-link svg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  vertical-align: middle;
  fill: white;
}

.navbar-nav .nav-link:hover svg,
.navbar-nav .nav-link.active svg {
  fill: #319795;
}

.navbar-nav .nav-link.active svg {
  fill: #ff5a5f;
}

/* --------- */
/* Home section */
/* --------- */

// #home {
//   justify-content: space-between;
//   justify-content: center;
//   margin: auto;
//   min-width: 100vh;
//   width: 100%;
//   font: 12px/18px 'opensans-bold', sans-serif;
//   //   flex-grow: 1;
//   color: white;
//   text-transform: uppercase;
//   z-index: 5;
//   letter-spacing: 5px;
// }

#home {
  justify-content: center;
  margin: auto;
  width: 100%;
  font: 12px/18px 'opensans-bold', sans-serif;
  color: white;
  text-transform: uppercase;
  z-index: 5;
  letter-spacing: 5px;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url('../images/bg4.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #319795;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #319795 !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #319795 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #3f818f;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #3f818f;
}

.home-social-icons:hover {
  color: #3f818f;
  box-shadow: 0 0 5px #3f818f;
  text-shadow: 0 0 2px #3f818f;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #3f818f !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: rgba(49, 151, 149, 0.4) !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  // background: url('../images/bg4.jpg') no-repeat;
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(49, 151, 149, 0.4) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(49, 151, 149, 0.4) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(49, 151, 149, 0.4) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(49, 151, 149, 0.4) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #3f818f !important;
  border-color: #3f818f !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #3f818f !important;
  border-color: #3f818f !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(49, 151, 149, 0.673) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(49, 151, 149, 0.173) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 1.7px solid rgba(79, 183, 179, 0.673) !important;
  box-shadow: 0 0 15px rgba(79, 183, 179, 0.8), 0 0 25px rgba(79, 183, 179, 0.8),
    0 0 35px rgba(79, 183, 179, 0.8), 0 0 45px rgba(79, 183, 179, 1);
}

.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  // position: relative !important;
  //   padding: 50px;
  margin: 30px;
  //   align-self: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  // padding-top: 30px !important;
  padding-bottom: 30px !important;
  padding-top: 110px !important;
  // padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  // padding-top: 10px !important;
}

.fork-btn-inner {
  // width: 100px;
  // height: 10px;
  // line-height: 1.4em !important;
  background-color: #934cce5e !important;
  // padding: 0.5rem 1.1rem !important;
  margin: auto;
  padding-left: 1px;
  padding-right: 1px;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}

.resumePdf {
  padding: auto;
  align-self: center;
}
// .resumePdf {
//   padding: 50px;
//   align-self: center;
//   border-radius: 20px;
//   background: var(--section-background-color);
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
// }

/* --------- */
/* utils */
/* --------- */

// :root {
// 	--primary-color: #6b46c1; // Adjust the purple color if needed
//   }

.modal-business {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;

  & header {
    font-family: 'Segoe UI', sans-serif; // You can change this to any font you prefer
    font-size: 1.5rem;
    color: var(--primary-color);
  }
}

// Style the modal buttons
.modal-business .chakra-button {
  height: 30px;
  min-width: 65px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}

// Style the login/logout button
.modal-business .chakra-button.logout-btn {
  background-color: #4a5568; // A different color for the logout button
}

// Add more padding to the modal content
.modal-business .chakra-modal__body {
  padding: 1.5rem;
}

// Add more padding to the VStack
.modal-business .chakra-stack {
  padding: 0.5rem;
}

/* --------- */
/* myprofile */
/* --------- */

.glass-background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  // backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  align-self: center;
  justify-self: center;
}

.open-login-btn {
  color: #623686;
  border-color: #2d1950;
  height: 75px;
  width: 150px;

  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  }
}

/* --------- */
/* carousel */
/* --------- */

/* --------- */
/* portExcode */
/* --------- */
